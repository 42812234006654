export const getNormalizedPlannerScreen = ({ screenData }) => {
  return screenData?.flat(2)?.map((screen, index) => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [screen?.attributes?.long, screen?.attributes?.lat],
      },
      properties: {
        screenType: index % 2 === 0 ? 'in' : 'out',
      },
    };
  });
};
