import React from 'react';
import indoor from '../Images/indoor.png';
import checkbox from '../Images/outdoor_icon.png';
import nordics from '../Images/nordics.svg';
import all_the_world from '../Images/all_the_world.svg';
import Vectorlatin_america from '../Images/Vectorlatin_america.svg';
import Groupnorth_america from '../Images/Groupnorth_america.svg';
import wester_europe from '../Images/wester_europe.svg';
import Groupall_radio from '../Images/Groupall_radio.svg';
import Groupin_door from '../Images/Groupin_door.svg';
import Vectorout_door from '../Images/Vectorout_door.svg';
import nordics_selected from '../Images/nordics_selected.svg';
import north_america_selected from '../Images/north_america_selected.svg';
import rest_world_selected from '../Images/rest_world_selected.svg';
import latin_america_selected from '../Images/latin_america_selected.png';
import western_euorpe_selected from '../Images/western_europe_selected.svg';
import indoor_outdoor_seleced from '../Images/indoor_outdoor_selected.svg';
import outdoor_selected from '../Images/outdoor_selected.svg';
import indoor_selected from '../Images/indoor_selected.svg';

export const CountryMap = {
  1: {
    name: 'nordics',
    center: [25.75, 63.0],
    zoomlevel: 4,
  },
  2: {
    name: 'wester_europe',
    center: [0.2, 50.5],
    zoomlevel: 5,
  },
  3: {
    name: 'north_america',
    center: [-99.1332, 53.0902],
    zoomlevel: 3,
  },
  4: {
    name: 'latin_america',
    center: [-90.0, 5.0],
    zoomlevel: 2.9,
  },
  5: {
    name: 'all_over_the_world',
    center: [10, 45],
    zoomlevel: 1.5,
  },
};

export const countryList = [
  {
    id: 1,
    label: 'Nordics',
    description: 'Denmark, Norway, Iceland, Sweden, Finland',
    defaultImage: nordics,
    selectedImage: nordics_selected,
    alt: 'Image 1',
    type: 1,
  },
  {
    id: 2,
    label: 'Western Europe',
    description:
      'United Kingdom, Germany, Netherlands, France, Belgium, Austria, Switzerland, Luxembourg',
    defaultImage: wester_europe,
    selectedImage: western_euorpe_selected,
    alt: 'Image 2',
    type: 2,
  },
  {
    id: 3,
    label: 'North America',
    defaultImage: Groupnorth_america,
    selectedImage: north_america_selected,
    description: 'US, Canada',
    alt: 'Image 3',
    type: 3,
  },
  {
    id: 4,
    label: 'Latin America',
    description: `Includes all the Central & South America`,
    defaultImage: Vectorlatin_america,
    selectedImage: latin_america_selected,
    alt: 'Image 4',
    type: 4,
  },
  {
    id: 5,
    label: 'All the World',
    defaultImage: all_the_world,
    selectedImage: rest_world_selected,
    alt: 'Image 5',
    type: 5,
  },
];

export const screenTypeList = [
  {
    id: 3,
    defaultImage: Groupall_radio,
    selectedImage: indoor_outdoor_seleced,
    alt: 'Location 1',
    label: () => {
      return (
        <>
          <img src={indoor} alt="indoor" width="10px" height="10px" />
          <img src={checkbox} alt="outdoor" width="10px" height="10px" />
          <span>All</span>
        </>
      );
    },
  },
  {
    id: 1,
    defaultImage: Groupin_door,
    selectedImage: indoor_selected,
    alt: 'Location 2',
    label: () => {
      return (
        <>
          <img src={checkbox} alt="outdoor" width="10px" height="10px" />
          <span>In Door</span>
        </>
      );
    },
  },
  {
    id: 2,
    defaultImage: Vectorout_door,
    selectedImage: outdoor_selected,
    alt: 'Location 3',
    label: () => {
      return (
        <>
          <img src={indoor} alt="indoor" width="10px" height="10px" />
          <span>Out Door</span>
        </>
      );
    },
  },
];

// export const dummyData = [
//   {
//     id: '3189',

//     type: 'dooh_screen',

//     attributes: {
//       name: "Bryan's office player2",

//       screen_id: 'broadsign.com:144974502',

//       provider: 'broadsign',

//       location: null,

//       lat: 10.75,

//       long: 65.0,

//       active: false,

//       country: 'Canada',

//       region: 'Manitoba',

//       city: 'Alexander',

//       ad_duration: null,

//       ad_types: null,

//       screen_image_url: null,

//       avg_monthly_reach: null,

//       instance_bid_floor_currency: '',

//       instance_bid_floor: null,

//       all_bid_floors: { eur: 12.5, usd: 15.0 },
//     },

//     relationships: {
//       dooh_publisher: {
//         data: {
//           id: '15',

//           type: 'dooh_publisher',

//           'name”': '” CS Digital Media”',
//         },
//       },

//       dooh_network: {
//         data: {
//           id: '164',

//           type: 'dooh_network',

//           name: 'Metro Rotterdam spreads',
//         },
//       },

//       dooh_channel: {
//         data: {
//           id: '6',

//           type: 'dooh_channel',

//           name: ' Public Transport',
//         },
//       },

//       size: {
//         data: {
//           id: '1563',

//           type: 'size',
//         },
//       },

//       osm_city: {
//         data: {
//           id: '171229',

//           type: 'city',
//         },
//       },

//       osm_region: {
//         data: {
//           id: '163169',

//           type: 'region',
//         },
//       },

//       osm_country: {
//         data: {
//           id: '22',

//           type: 'country',
//         },
//       },
//     },
//   },
// ];
