import React from 'react';
import { Provider } from 'react-redux';
import store from '@store/index';
import { setAxiosBaseUrl } from '@utils/requestHandler';
import AppRouter from './Router';
import { BrowserRouter } from 'react-router-dom';
import '../scss/index.scss';

const App = () => {
  setAxiosBaseUrl(import.meta.env.VITE_APP_API_BASE_URL);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
