import PlannerScreen from '@apps/PlannerScreen';
import WelcomeScreen from '@apps/WelcomeScreen';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<WelcomeScreen />} />
      <Route
        path="/planner-screen/:type/:campaignLocation"
        element={<PlannerScreen />}
      />
    </Routes>
  );
};

export default AppRouter;
