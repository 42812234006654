import { getNormalizedPlannerScreen } from '@apps/PlannerScreen/utils';
import { PlannerActions } from '@store/actions';

const initialState = {
  tableList: [],
  screenData: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PlannerActions.FETCH_DOOH_SCREENS_SUCCESS: {
      const data = action.payload;

      return {
        ...state,
        tableList: data,
      };
    }

    case PlannerActions.FETCH_PLANNER_SCREENS_SUCCESS: {
      const data = action.payload;

      return {
        ...state,
        screenData: getNormalizedPlannerScreen({ screenData: data }),
      };
    }

    default:
      return state;
  }
}
