import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { actionHandler, PlannerActions } from '@store/actions';
import { connect } from 'react-redux';
import { countryList, screenTypeList } from '@constants/index';

const WelcomeScreen = ({ dispatchAction }) => {
  const [selectedSetupImage, setSelectedSetupImage] = useState('');
  const [selectedLocationImage, setSelectedLocationImage] = useState('');
  const [submitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleSetupChange = (event) => {
    setSelectedSetupImage(event.target.value);
  };

  const handleLocationChange = (event) => {
    setSelectedLocationImage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatchAction(PlannerActions.FETCH_PLANNER_SCREENS, {
      setLoading: setIsSubmitting,
      payload: {
        regionId: Number(selectedSetupImage),
        campaign_location: Number(selectedLocationImage),
      },
      onSuccess: () => {
        navigate(
          `/planner-screen/${selectedSetupImage}/${selectedLocationImage}`
        );
      },
    });
  };

  const isButtonDisabled =
    !(selectedSetupImage && selectedLocationImage) || submitting;

  return (
    <div className="welcome-screen__container">
      <form onSubmit={handleSubmit} className="welcome-screen__form">
        <h1 className="welcome-screen__heading">
          Where do you plan to set up the campaign?
        </h1>
        <ul className="card-container">
          {countryList.map((image) => (
            <li key={image.id}>
              <div className="card">
                <div
                  style={{
                    border: '1px solid #0000001A',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px',
                    boxShadow: '0px 11px 29.4px 0px #E6E7ED',
                    width: '168px',
                    height: '168px',
                  }}
                >
                  <img
                    src={
                      Number(selectedSetupImage) === image.id
                        ? image.selectedImage
                        : image.defaultImage
                    }
                    alt={image.alt}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="radio"
                    className="welcome-screen__radio"
                    name="setupImage"
                    value={image.id}
                    style={{ margin: 'unset' }}
                    checked={Number(selectedSetupImage) === image.id}
                    onChange={handleSetupChange}
                  />
                  <p className="welcome-screen__card-subtitle">{image.label}</p>
                </div>
              </div>

              <p className="selected-city">{image.description}</p>
            </li>
          ))}
        </ul>

        <h1 className="welcome-screen__heading">
          What is the location of campaign?
        </h1>
        <ul className="card-container">
          {screenTypeList.map((image) => (
            <li key={image.id}>
              <div className="card">
                <div
                  style={{
                    border: '1px solid #0000001A',
                    padding: '31px, 29px, 31px, 29px',
                    borderRadius: '10px',
                    boxShadow: '0px 11px 29.4px 0px #E6E7ED',
                    width: '104px',
                    height: '104px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={
                      Number(selectedLocationImage) === image.id
                        ? image?.selectedImage
                        : image?.defaultImage
                    }
                    alt={image.alt}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="radio"
                    className="welcome-screen__radio"
                    name="locationImage"
                    style={{ margin: 'unset' }}
                    value={image.id}
                    checked={Number(selectedLocationImage) === image.id}
                    onChange={handleLocationChange}
                  />
                  <p
                    className="welcome-screen__card-subtitle"
                    style={{
                      display: 'flex',
                      gap: '4px',
                      alignItems: 'center',
                    }}
                  >
                    {image.label?.()}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <button
          type="submit"
          disabled={isButtonDisabled}
          className={`welcome-screen__form-submit-button ${isButtonDisabled ? 'button--disabled' : 'button--active'}`}
        >
          Next
        </button>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (type, payload) => {
    dispatch(actionHandler(type, payload));
  },
});

export default connect(null, mapDispatchToProps)(WelcomeScreen);
