import { createSelector } from 'reselect';

const getTableList = (state) => state.planner.tableList;

const getScreenList = (state) => state.planner.screenData;

export const getTableListSelector = createSelector(
  [getTableList],
  (tableData) => tableData
);

export const getScreenListSelector = createSelector(
  [getScreenList],
  (screenList) => screenList
);
